import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import Heading from '../../components/Heading';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import withLayout from '../../withLayout';
import ListGroupDefault from '../../examples/ListGroup/Default';
import ListGroupLinked from '../../examples/ListGroup/Linked';
import ListGroupActive from '../../examples/ListGroup/Active';
import ListGroupDisabled from '../../examples/ListGroup/Disabled';
import ListGroupStyle from '../../examples/ListGroup/Style';
import ListGroupStyleActions from '../../examples/ListGroup/StyleActions';
import ListGroupFlush from '../../examples/ListGroup/Flush';
import ListGroupHorizontal from '../../examples/ListGroup/Horizontal';
import ListGroupHorizontalResponsive from '../../examples/ListGroup/HorizontalResponsive';
import ListGroupTabs from '../../examples/ListGroup/Tabs';
import styles from '../../css/examples.module.scss';
export const query = graphql`
  query ListGroupQuery {
    ListGroup: componentMetadata(displayName: { eq: "ListGroup" }) {
      ...ComponentApi_metadata
    }
    ListGroupItem: componentMetadata(displayName: { eq: "ListGroupItem" }) {
      ...ComponentApi_metadata
    }
    ListGroupItemAction: componentMetadata(
      displayName: { eq: "ListGroupItemAction" }
    ) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "list-groups"
    }}>{`List groups`}</h1>
    <p className="lead">
  List groups are a flexible and powerful component for displaying a
  series of content. Modify and extend them to support just about any
  content within.
    </p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
    <ReactPlayground codeText={ListGroupDefault} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "active-items"
    }}>{`Active items`}</h3>
    <p>{`Set the `}<inlineCode parentName="p">{`active`}</inlineCode>{` prop to indicate the list groups current active selection.`}</p>
    <ReactPlayground codeText={ListGroupActive} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "disabled-items"
    }}>{`Disabled items`}</h3>
    <p>{`Set the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` prop to prevent actions on a `}<inlineCode parentName="p">{`<ListGroup.Item>`}</inlineCode>{`. For elements
that aren't naturally disable-able (like anchors) `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` handlers are added
that call `}<inlineCode parentName="p">{`preventDefault`}</inlineCode>{` to mimick disabled behavior.`}</p>
    <ReactPlayground codeText={ListGroupDisabled} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "actionable-items"
    }}>{`Actionable items`}</h3>
    <p>{`Toggle the `}<inlineCode parentName="p">{`action`}</inlineCode>{` prop to create `}<em>{`actionable`}</em>{` list group
items, with disabled, hover and active styles. List item actions will render
a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` (depending on the presence of an `}<inlineCode parentName="p">{`href`}</inlineCode>{`) by default but
can be overridden by setting the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop as usual.`}</p>
    <p>{`List items `}<inlineCode parentName="p">{`actions`}</inlineCode>{` are distinct from plain items to ensure that click or tap
affordances aren't applied to non-interactive items.`}</p>
    <ReactPlayground codeText={ListGroupLinked} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "flush"
    }}>{`Flush`}</h3>
    <p>{`Add the `}<inlineCode parentName="p">{`flush`}</inlineCode>{` variant to remove outer borders and rounded corners to render list group items
edge-to-edge in a parent container `}<a parentName="p" {...{
        "href": "/components/cards/#list-groups"
      }}>{`such as a `}<inlineCode parentName="a">{`Card`}</inlineCode></a>{`.`}</p>
    <ReactPlayground codeText={ListGroupFlush} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "horizontal"
    }}>{`Horizontal`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{` prop to make the ListGroup render horizontally. Currently `}<strong parentName="p">{`horizontal list groups cannot be combined with flush list groups.`}</strong></p>
    <ReactPlayground codeText={ListGroupHorizontal} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <p>{`There are responsive variants to `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{`: setting it to `}<inlineCode parentName="p">{`{sm|md|lg|xl}`}</inlineCode>{` makes the list group horizontal starting at that breakpoint’s `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={ListGroupHorizontalResponsive} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "contextual-classes"
    }}>{`Contextual classes`}</h3>
    <p>{`Use contextual variants on `}<inlineCode parentName="p">{`<ListGroup.Item>`}</inlineCode>{`s to style them with a stateful background and color.`}</p>
    <ReactPlayground codeText={ListGroupStyle} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <p>{`When paired with `}<inlineCode parentName="p">{`action`}</inlineCode>{`s, additional hover and active styles apply.`}</p>
    <ReactPlayground codeText={ListGroupStyleActions} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <Callout title="Conveying meaning to assistive technologies" mdxType="Callout">
  Using color to add meaning only provides a visual indication,
  which will not be conveyed to users of assistive technologies – such as screen readers.
  Ensure that information denoted by the color is either obvious from the content itself
  (e.g. the visible text), or is included through alternative means,
  such as additional text hidden with the <code>.sr-only</code> class.
    </Callout>
    <h2 {...{
      "id": "tabbed-interfaces"
    }}>{`Tabbed Interfaces`}</h2>
    <p>{`You can also use the `}<a parentName="p" {...{
        "href": "/components/tabs/"
      }}>{`Tab`}</a>{` components to create ARIA compliant tabbable
interfaces with the `}<inlineCode parentName="p">{`<ListGroup>`}</inlineCode>{` component. Swap out the `}<inlineCode parentName="p">{`<Nav>`}</inlineCode>{` component
for the list group and you are good to go.`}</p>
    <ReactPlayground codeText={ListGroupTabs} exampleClassName={styles.listGroup} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.ListGroup} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ListGroupItem} exportedBy={props.data.ListGroup} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      